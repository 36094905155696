<template>
  <div class="fieldgroup">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'KFieldGroup',
  props: {
    languagePrefix: {
      required: false,
      type: String,
      default: '',
    },
    fieldProps: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  provide() {
    return {
      fieldGroup: {
        languagePrefix: this.languagePrefix,
        computedFieldProps: this.computedFieldProps,
      },
    };
  },
  computed: {
    computedFieldProps() {
      return {
        filled: false,
        validateOnBlur: true,
        ...this.fieldProps,
      };
    },
  },
};
</script>
