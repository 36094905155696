
import { translateField } from '@/application/util/translation.ts';
import Vue, { PropType, VueConstructor } from 'vue';
import { InputValidationRules } from 'vuetify';

interface FieldGroup {
  languagePrefix: string,
  computedFieldProps: Record<string, unknown>
}

export default (Vue as VueConstructor<Vue & { fieldGroup: FieldGroup}>).extend({
  name: 'BaseField',
  inject: {
    fieldGroup: {
      default: undefined,
    },
  },
  props: {
    rules: {
      type: Array as PropType<InputValidationRules>,
      default: () => ([]),
    },
    field: {
      type: String,
    },
    validationField: {
      type: String,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [String, Number],
    },
    grid: {
      type: [Object, String],
    },
    hideRequiredAsterisk: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    translatedLabel(): string {
      if (this.required && !this.hideRequiredAsterisk) {
        return `${this.computedLabel} *`;
      }
      return this.computedLabel;
    },
    computedLabel(): string {
      if (typeof this.label !== 'undefined') {
        return this.label;
      }
      if (this.fieldGroup && this.fieldGroup.languagePrefix) {
        return translateField(this.field, this.fieldGroup.languagePrefix);
      }
      return this.label || this.field;
    },
    computedRules(): InputValidationRules {
      const rules = [...this.rules, () => this.getServerError()];
      if (this.required) {
        rules.push((v) => (!!v || v === 0 || v === false) || this.$t('validation.required', { field: this.computedLabel }) as string);
      }
      return rules;
    },
    fieldProps() {
      if (this.fieldGroup && this.fieldGroup.computedFieldProps) {
        return { ...this.fieldGroup.computedFieldProps, ...this.$attrs };
      }
      return { ...this.$attrs };
    },

  },
  methods: {
    getServerError(): string | boolean {
      const error = this.$store.getters['error/find'](this.validationField || this.field);
      if (error) {
        this.removeServerError(this.field);
        return error;
      }

      return true;
    },
    removeServerError(key: string): void {
      this.$store.commit('error/remove', key);
    },
  },
});
